import React from "react";
import AcquistaLayout from "../../components/acquista-layout";
import AcquistaThanks from "../../components/acquista-thanks";

const GrazieAcquistoCardPage = () => {
  return (
    <AcquistaLayout>
      <AcquistaThanks>
        <h1 className="fw-bold">Grazie per esserti iscritto all'Intranet Italia Day.</h1>
        <p>Riceverai via mail il biglietto dell’evento di Milano.</p>
      </AcquistaThanks>
    </AcquistaLayout>
  );
};

export default GrazieAcquistoCardPage;
